$medium-font-size: 1.125rem;
$large-font-size: 1.125rem;
$root-line-height: 1.7;
$small-breakpoint: 34rem;
$large-breakpoint: 56rem;
$root-font-family: Fira Sans, sans-serif;
$code-font-family: Fira Mono, Menlo, Monaco, "Courier New", monospace;
$sidebar-bg-color: #ac4142;
$sidebar-width: 20rem;

@import "hydeout";

html {
    // Fix scrollbar color in Firefox
    scrollbar-color: #a1a1a1 $gray-2;
}

div.highlight {
    padding: 0;
}

.links__date {
    font-weight: normal;
}

@media (min-width: $small-breakpoint) {
    html {
        font-size: $large-font-size;
    }
}

@media (max-width: $small-breakpoint) {
    #sidebar {
        padding-top: 1.5rem;
    }
    #sidebar,
    .content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .container > header {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

#sidebar .site-title {
    font-size: 1.375rem;
}
.home #sidebar .site-title {
    font-size: 3.6rem;
}
@media (min-width: $large-breakpoint) {
    #sidebar .site-title {
        font-size: 3.6rem;
    }
}

#sidebar .subscribe-form {
    margin-top: 1rem;
    .subscribe-row {
        border-radius: $border-radius;
        display: flex;
        padding: 1px;
        background-color: #fff;
      
        input {
            border: 0;
            padding: 1em 1em;
            &:focus {
                outline: solid 2px $link-color;
            }
        }
      
        input + input { margin-left: 2px; }
      
        input[type='email'] {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 10em;
        }
      }
}

// List of posts
.posts-list small {
    font-weight: normal;
}


// Images can be wider then main content
@media (min-width: $large-breakpoint) {
    img {
        max-width: calc(100vw - 20rem - 8rem);
    }
}
